import React, { lazy } from "react";
import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
    AtSign,
} from "react-feather";
import {
    DropdownToggle,
    Dropdown
} from "../../ui/dropdown/dropdown";
import {
    Avatar,
    AvatarInitial,
} from "../../ui/avatar/avatar"
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { BroadcastChannel } from 'broadcast-channel';
import { teknolink } from "../../../../appconfig";
import { Button } from "../../../../components"
import { useStylesButton } from "../../../../shared/styles/commonStyles";

const roles = localStorage.getItem('roles');


const Help = lazy(() => import("../../../../pages/help/Help"));
// "./pages/help/Help"

const logoutChannel = new BroadcastChannel('logout');

export const logOut = () => {
    logoutChannel.postMessage("Logout")
    localStorage.removeItem("token")
    window.location.href = window.location.origin + "/login";
}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logOut();
        logoutChannel.close();

    }
}

const ProfileDropdown: React.FC = () => {

    const classesButton = useStylesButton();

    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm">
                    <AvatarInitial>RDC</AvatarInitial>
                </StyledAvatar>
            </DropdownToggle>
            <StyledDropMenu>
                <Avatar size="lg">
                    <AvatarInitial>RDC</AvatarInitial>
                </Avatar>
                <StyledAuthorName>KullanıcıAdı</StyledAuthorName>
                <StyledAuthorRole>KullanıcıRolü</StyledAuthorRole>
                <StyledDropItem path="/profile">
                    <Edit3 size="24" />
                    Profili incele
                </StyledDropItem>
                <StyledDivider />
                <StyledDropItem

                    path="/help"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Yardım
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Hesap ayarları
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Gizlilik ayarları
                </StyledDropItem>{roles?.includes('Technopark') &&
                    (
                        <StyledDropItem path={teknolink} mt="10px">
                            <AtSign size="24" />
                            TeknoPark
                        </StyledDropItem>
                    )}
                <StyledDropItem path="/login" mt="10px">

                    <Button
                        iconButton
                        size="lg"
                        color="white"
                        className={classesButton.buttonList}
                        onClick={logOut}
                    >
                        <ExitToAppIcon
                            width={18}
                            height={18}
                            className={classesButton.buttonIcon}
                        />
                        Çıkış Yap
                    </Button>
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
