import styled, { themeGet, css } from "../styled";
import { Table, Row } from "../../components";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import { TableRow } from "@mui/material";

interface TableCellProps {
    isCompleted?: boolean;
    children: React.ReactNode;
}

export const TableTwo = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
`;

export const LeftArrow = styled.div`
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);

  border-top: 120px solid transparent;
  border-bottom: 120px solid transparent;
  border-right: 40px solid rgba(200, 200, 200, 0.7); 
  cursor: pointer;
  z-index: 1050;
  transition: all 0.3s ease;

  &:hover {
    border-right-color: rgba(255, 255, 255, 0.9); 
    transform: translateY(-50%) scale(1.2);
    left: -60px; 
  }
`;

export const RightArrow = styled.div`
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 120px solid transparent;
  border-bottom: 120px solid transparent;
  border-left: 40px solid rgba(200, 200, 200, 0.7); 
  cursor: pointer;
  z-index: 1050;
  transition: all 0.3s ease;

  &:hover {
    border-left-color: rgba(255, 255, 255, 0.9); 
    transform: translateY(-50%) scale(1.2);
    right: -60px;  
  }
`;
export const TableHeader = styled.th`
    padding: 12px 15px;
    background-color: white;  
    color: black;  
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
`;


export const TableCell = styled.td<TableCellProps & { isTotalRow?: boolean }>`
  padding: 12px 15px;
  border: 1px solid #ddd;

  ${({ isCompleted, isTotalRow }) =>
        isTotalRow
            ? css`
          color: black;  
        `
            : isCompleted
                ? css`
          color: green;  
        `
                : css`
          color: blue; 
        `}
`;

export const TableRowTwo = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2; 
    }
    &:hover {
        background-color: #ddd;  
    }
`;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        scroll: {
            overflowY: "scroll",
            height: "100vh",
        },
        table: {
            textAlign: "center",
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
    })
);

export const StyledButton = styled(Button)`
  && {
    color: #1b2e4b;
    background-color: #e5e9f2;
    border-color: #e5e9f2;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    margin-left: auto;
    font-weight: bold;
    padding: 0.6rem 1rem;
    line-height: 1;
    margin-bottom: 10px;

    &:hover {
      background-color: #c3cce1;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  background-color: #000;
  color: #fff;
`;

export const useCurrencyInputStyles = makeStyles((theme) => ({
    inputControl2: {
        width: '100%', // Genişlik
        height: '30px', // Yükseklik
        '& input': {
            padding: '4px 8px', // Giriş alanı için padding
            height: '100%', // Yüksekliği %100 yap
            boxSizing: 'border-box', // Kutunun boyutunu sınırla
            borderRadius: '4px', // İsteğe bağlı: köşe yuvarlama
        },
    },
}));

export const useTextFieldStyles = makeStyles((theme) => ({
    inputControl: {
        width: '100%', // Genişlik
        height: '30px', // Yükseklik
        '& input': {
            padding: '4px 8px', // Giriş alanı için padding
            height: '100%', // Yüksekliği %100 yap
            boxSizing: 'border-box', // Kutunun boyutunu sınırla
        },
    },
}));
export const useStylesLink = makeStyles((theme: Theme) =>
    createStyles({
        rdcLink: {
            color: "black",
        },
    })
);

export const StyledTheadTR2 = styled.tr`
  &:first-child {
    th {
      position: sticky;
      top:30px;
       '.dark-mode &': {
                color: 'black !important'
            },
      width: auto;
      z-index: 0;
      background-color: rgb(127 154 205) !important;
      text-align: left;
      font-size: 13px;
      ${(props) =>
        props.theme.name === "dark" &&
        css`
          background-color: rgba(255, 255, 255, 0.05);
        `}
    }
  }

  th:first-child {
    border-left-width: 0;
    text-align: left;
  }

  th:last-child {
    border-right-width: 0;
  }

  &:last-child {
    th {
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
             '.dark-mode &': {
                color: 'black !important'
            },
      color: ${themeGet("colors.text3")};
      &:not(:first-child) {
        text-align: left;
      }
    }
  }
`;
export const StyledTableContainer = styled.div`
  overflow-y: scroll;  
  max-height: 500px;   
  width: 100%;
`;
export const useCommonStyles = makeStyles((theme: Theme) =>
    createStyles({
        scroll: {
            overflowY: 'scroll',
            height: '100vh',
        },

        buttonList: {
            fontWeight: "bold",
            margin: "7px",
            '.dark-mode &': {
                backgroundColor: 'rgb(55, 78, 125) !important',
                color: 'rgb(205, 212, 224) !important'
            },
        },
        buttonTransfer: {
            fontWeight: "bold",
            margin: "7px",
        },
        buttonExcelTransfer: {
            position: "absolute",
            marginRight: "10px",
            fontWeight: "bold",
            marginTop: "-80px",
            width: "auto",
            color: "#959393",
            height: "2.8rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "14px",
            right: 160,
        },
        buttonSave: {

            fontWeight: "bold",
            width: "auto",
            height: "2.8rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "14px",
        },
        buttonIcon: {
            marginRight: "5px",
        },
        buttonIconDelete: {
            marginRight: "20px",
            color: "danger",
        },

        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            '.dark-mode &': {
                backgroundColor: 'rgb(58, 71, 95) !important'
            },
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },

        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
            '.dark-mode &': {
                backgroundColor: '#1c273c !important'
            },
        },

        header: {
            marginLeft: "115px",
            height: "60px",
            '.dark-mode &': {
                color: '#ffffff'
            }
        },

        inputControl: {
            position: "absolute",
            width: "92%",
            color: "black",
            height: "94%",
            borderRadius: "4px",
            borderColor: "#c4c4c4",
            fontSize: "16px",
        },
        inputControl2: {
            position: "relative",
            width: "100%",
            color: "black",
            height: "3.4rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "16px",
            top: "-11px",
        },

        // Label styles
        labelControl: {
            position: "relative",
            width: "100%",
            color: "#959393",
            '.dark-mode &': {
                color: '#ffffff'
            },
            fontSize: "14px",
            top: "-11px",
        },
    })
); export const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            fontWeight: "bold",
            margin: "7px",
            '.dark-mode &': {
                backgroundColor: 'rgb(55, 78, 125) !important',
                color: 'rgb(205, 212, 224) !important',
                border: '1px solid rgb(55, 78, 125)!important'
            },
        },
        buttonTransfer: {
            fontWeight: "bold",
            margin: "7px",
            '.dark-mode &': {
                backgroundColor: 'rgb(41 51 73) !important',
                color: 'rgb(205, 212, 224) !important',
                border: '1px solid rgb(41 51 73)!important'
            },
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap', /* For making responsive */
            width: '100%',
            padding: " 0 10px"
        },
        buttonGroupLeft: {
            display: 'flex',
            justifyContent: 'flex-start', /* Align buttons to the left */
            gap: '10px',
        },
        buttonGroupRight: {
            display: 'flex',
            justifyContent: 'flex-end', /* Align buttons to the right*/
            gap: '10px',
        },
        buttonApproval: {
            fontWeight: "bold",
            marginTop: "7px",
            whiteSpace: 'nowrap'
        },
        buttonList2: {
            fontWeight: "bold",
            marginLeft: '80px',
            margin: "7px",
        },
        buttonSave: {
            fontWeight: "bold",
            margin: "7px",
            '.dark-mode &': {
                backgroundColor: 'rgb(105 117 143) !important',
                color: 'rgb(205, 212, 224) !important',
                border: '1px solid rgb(105 117 143) !important'
            },
        },
        buttonLinkTechnic: {
            fontSize: "13px",
        },
        buttonIcon: {
            marginRight: "5px",
        },
        buttonIconDelete: {
            position: "relative",
            textAlign: "center",
            color: "danger",
        },
        buttonIconUpdate: {
            position: "relative",
            textAlign: "center",
        },
        buttonExcelTransfer: {
            fontWeight: "bold",
            width: "auto",
            color: "#959393",
            height: "2.8rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "14px",
            margin: '7px',
            '.dark-mode &': {
                backgroundColor: 'rgb(41 51 73) !important',
                color: 'rgb(205, 212, 224) !important',
                border: '1px solid rgb(41 51 73)!important'
            },
        },
        buttonIconUpdateLeft: {
            position: 'relative',
            textAlign: 'center',
            marginLeft: '-1.8rem',
            height: '100%',
            marginTop: '20px',
            fontSize: '14px',
        },
        buttonIconUpdateRight: {
            position: 'relative',
            textAlign: 'left',
            padding: '1.5rem',
            height: '100%',
            // marginTop: '20px',
            fontSize: '14px',
            '.dark-mode &': {
                backgroundColor: '#333333 !important'
            },
        },
        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            '.dark-mode &': {
                backgroundColor: 'rgb(58, 71, 95) !important'
            },
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },
        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
            '.dark-mode &': {
                backgroundColor: '#1c273c !important'
            },
        },
    })
);

export const useStylesSelect = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            position: "absolute",
            width: "92%",
            color: "black",
            "& label.Mui-focused": {
                color: "#3b8c8c",
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "#3b8c8c",
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "black",
                },
                "&:hover fieldset": {
                    borderColor: "orange",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#3b8c8c",
                },
            },
        },
        rowHeight: {
            height: "8rem",
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        redIcon: {
            color: 'red',
        },
        greenIcon: {
            color: 'green',
        },
        loadingIcon: {
            color: 'green',
        },
        underline: {
            borderBottom: '3px solid black'
        },
        antSelectSelector: {
            "& .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
                height: "3.1rem",
                borderRadius: "4px",
                borderColor: "#AEADAD",
                '.dark-mode &': {
                    backgroundColor: '#333333',
                    borderColor: "#ffffff"
                }
            },
        },
        inputControl: {
            position: "absolute",
            width: "92%",
            color: "black",
            height: "94%",
            borderRadius: "4px",
            borderColor: "#c4c4c4",
            fontSize: "16px",
        },
        inputControl2: {
            position: "relative",
            width: "100 %",
            color: "black",
            height: "3.1rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "16px",
            top: "-9px",
        },
        inputControlTwo: {

            position: "relative",

            width: "20vw",

            color: "black",

            height: "3.1rem",

            borderRadius: "4px",

            borderColor: "#AEADAD",

            fontSize: "16px",

            top: "-11px",

            minWidth: "200px"

        },
        inputControl3: {
            position: 'relative',
            color: 'black',
            height: '2.7rem',
            borderRadius: '4px',
            borderColor: '#AEADAD',
            fontSize: '16px',
            top: '3px'
        },
        inputControl4: {
            position: 'relative',
            width: '100%',
            color: 'black',
            height: '2.7rem',
            borderRadius: '4px',
            borderColor: '#AEADAD',
            fontSize: '16px',
            top: '21px'
        },
        inputControl5: {
            position: 'relative',
            width: '100%',
            color: 'black',
            height: '2rem',
            borderRadius: '4px',
            borderColor: '#AEADAD',
            fontSize: '16px',
            // top: '21px'
        },
        labelControl: {
            position: "relative",
            width: "100%",
            color: "#959393",
            top: "-9px",
            '.dark-mode &': {
                color: '#ffffff'
            },
            //height: '3.2rem',

            fontSize: "14px",
        },
        labelControl2: {
            position: "relative",
            width: "max-content",
            display: "flex",
            fontSize: "16px",
            top: "-11px",
            minWidth: "200px"
        },
        labelControlTwo: {
            position: "relative",
            width: "100%",
            color: "#959393",
            '.dark-mode &': {
                color: '#ffffff'
            },
            //height: '3.2rem',

            fontSize: "14px",
        },
        fileControl: {
            position: "relative",
            width: "65%",
            color: "#959393",
            padding: "5px",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "14px",
            margin: "7px",
            fontWeight: "bold",
            float: "right",
        },
    })
);

export const useStylesHeader = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginLeft: "117px",
            height: "40px",
            '.dark-mode &': {
                color: '#ffffff'
            }
        },
        scroll: {
            overflowY: "scroll",
            height: "100vh",
        },
        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            '.dark-mode &': {
                backgroundColor: 'rgb(58, 71, 95) !important'
            },
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },
        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
            '.dark-mode &': {
                backgroundColor: '#1c273c !important'
            },
        },
    })
);

export const Container = styled.div`
    margin: 50px 110px 5px;
`;

export const StyledTable = styled(({ ...rest }) => <Table {...rest} />)``;

export const StyledTheadTR = styled.tr`
    &:first-child {
        th {
            border-top-width: 0;
            font-size: 16px;
            background-color:rgb(127 154 205) !important;
            text-align: center;
            ${(props) =>
        props.theme.name === "dark" &&
        css`
                    background-color: rgba(255, 255, 255, 0.05);
                `}
        }
    }
`;

export const StyledTD = styled.td`
    border-color: ${themeGet("colors.border")};
    font-size: 11px;
    position: relative;
    align-items: center !important;
    text-align: center;
    margin: 0px auto;
    vertical-align: middle !important;
    white-space: nowrap;
`;

export const FlexContainer = styled.div`
    display: flex;
    width: auto;
    gap: 50px;
    flex: 1;

    @media (max-width: 1024px) {
        display: block;
    }

    @media (max-width: 480px) {
        display: block;
    }
`;
export const StyledRow = styled(Row)`
  display: block;

  .react-datepicker-wrapper {
    display: grid;
  }
`;
export const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '52px'
    }
});
export const ErrorDatePickerWrapper = styled.div<{ error?: boolean }>`
  .react-datepicker__input-container input {
    border: 1px solid ${(props) => (props.error ? "red" : "#AEADAD")};
    box-shadow: ${(props) => (props.error ? "0 0 1px red" : "none")};
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
  }
`;